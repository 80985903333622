import {
  ClockIcon,
  MapIcon,
  BookmarkIcon
} from "@heroicons/react/24/solid";

export const featuresData = [
  {
    color: "blue",
    title: "Duración",
    icon: ClockIcon,
    description:
      "4 años",
  },
  {
    color: "red",
    title: "Campus",
    icon: MapIcon,
    description:
      "Santiago - Santo Domingo",
  },
  {
    color: "teal",
    title: "Sitio Informativo",
    icon: BookmarkIcon,
    description:
      "https://eict.pucmm.edu.do/telematica/",
  },
];

export default featuresData;
