import React, { useState, useEffect } from "react";

export function GaleriaImagenes({ images }) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [zoomed, setZoomed] = useState(false);

  const openViewer = (index) => {
    setSelectedImageIndex(index);
    setZoomed(false);
    document.body.classList.add("no-scroll");
  };

  const closeViewer = () => {
    setSelectedImageIndex(null);
    setZoomed(false);
    document.body.classList.remove("no-scroll");
  };

  const goToPrevious = () => {
    if (selectedImageIndex !== null) {
      setSelectedImageIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
      setZoomed(false);
    }
  };

  const goToNext = () => {
    if (selectedImageIndex !== null) {
      setSelectedImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
      setZoomed(false);
    }
  };

  const toggleZoom = () => {
    setZoomed((prevZoomed) => !prevZoomed);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (selectedImageIndex !== null) {
        if (event.key === "Escape") {
          closeViewer();
        } else if (event.key === "ArrowLeft") {
          goToPrevious();
        } else if (event.key === "ArrowRight") {
          goToNext();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedImageIndex]);

  return (
    <div className="gallery">
      {images.map((image, index) => (
        <img
          key={index}
          src={image.src}
          alt={image.alt}
          onClick={() => openViewer(index)}
          className="gallery-image"
        />
      ))}

      {selectedImageIndex !== null && (
        <div className="image-viewer">
          <button className="close-button" onClick={closeViewer}>
            ✖
          </button>
          <button
            className="previous-button"
            onClick={goToPrevious}
            disabled={selectedImageIndex === 0}
          >
            {"<"}
          </button>
          <img
            src={images[selectedImageIndex].src}
            alt={images[selectedImageIndex].alt}
            className={`viewer-image ${zoomed ? "zoomed" : ""}`}
            onClick={toggleZoom}
          />
          <button
            className="next-button"
            onClick={goToNext}
            disabled={selectedImageIndex === images.length - 1}
          >
            {">"}
          </button>
        </div>
      )}
    </div>
  );
}

export default GaleriaImagenes;
