import { Footer } from "../widgets/layout/footer";
import React from "react";
import { Alert } from "@material-tailwind/react";
import { patrocinadores } from "./../data/patrocinadores";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "@material-tailwind/react";
import { Typography, Tooltip } from "@material-tailwind/react";

export function Promocion() {
  function tipoimagen(imagen) {
    if (imagen === "RadicalOne") {
      return <img alt="Radical One" src={require("./../img/RadicalOne.png")} />;
    }
    if (imagen === "Iteprof") {
      return <img alt="Radical One" src={require("./../img/ITEPROF.png")} />;
    }
  }
  return (
    <>
      <section className="relative block h-[50vh]">
        <div className="bg-profile-background absolute top-0 h-full w-full bg-[url('https://blogthinkbig.com/wp-content/uploads/sites/4/2019/12/technology-cable-electricity-energy-electronic-device-electrical-supply-104676-pxhere.com_-e1577344842465.jpg?resize=450%2C220')] bg-cover bg-center" />
        <div className="absolute top-0 h-full w-full bg-black/75 bg-cover bg-center" />
      </section>
      <section className="relative bg-blue-gray-50/50 pt-16 pb-2 px-4">
        <div className="container mx-auto">
          <div className="relative mb-6 -mt-64 flex w-full min-w-0 flex-col break-words rounded-3xl bg-white shadow-xl shadow-gray-500/5">
            <div className="px-6">
              <div className="flex flex-wrap justify-center">
                <div className="flex w-full justify-center px-4 lg:order-2 lg:w-3/12"></div>
              </div>
              <div className="my-8 text-center">
                <Typography variant="h2" color="blue-gray" className="mb-2">
                  NUESTROS PATROCINADORES
                </Typography>
                <div
                  style={{
                    backgroundColor: "#005EBC",
                    height: "2px",
                    width: "50%",
                    margin: "auto",
                  }}
                ></div>
              </div>

              <div className="mt-8 mb-10 grid grid-cols-1 gap-6 gap-x-124 md:grid-cols-2 xl:grid-cols-4">
                {patrocinadores.map(
                  ({ key, img, nombre, descripcion, instagram }) => (
                    <Card className="max-w-[24rem] overflow-hidden">
                      <CardHeader
                        floated={false}
                        shadow={false}
                        color="transparent"
                        className="m-0 rounded-none"
                      >
                        {tipoimagen(img)}
                      </CardHeader>
                      <CardBody>
                        <Typography variant="h4" color="blue-gray">
                          {nombre}
                        </Typography>
                        <Typography
                          variant="lead"
                          color="black"
                          className="leading-6 mt-3 font-normal text-sm text-justify"
                        >
                          {descripcion}
                        </Typography>
                      </CardBody>{" "}
                      <CardFooter className="flex justify-center gap-7 pt-2">
                        {/* <Tooltip content="Instagram"> */}
                        <Tooltip content="Instagram">
                          <Typography
                            as="a"
                            href={instagram}
                            variant="lead"
                            color="purple"
                            target="_blank"
                            textGradient
                          >
                            <i className="fab fa-instagram" />
                          </Typography>
                        </Tooltip>
                      </CardFooter>
                    </Card>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bg-blue-gray-50/50">
        <Footer />
      </div>
    </>
  );
}

export default Promocion;
