export const actividadesData = [
  {
    img: "OpenHouse_PUCMM",
    name: "OPEN HOUSE PUCMM 2023",
    done: true,
    description:
      "Open House PUCMM es una actividad académica que busca apoyar la toma de decisiones" +
      "de los jóvenes preuniversitarios de diferentes centros educativos al momento" +
      "de elegir su carrera universitaria. El Comite de Estudiantes de Ingeniería" +
      "Telemática colabora en esta actividad mostrando las distintas partes de la" +
      "carrera a los potenciales nuevos estudiantes.",
    fecha: "Marzo",
  },{
    img: "Voleyball",
    name: "TORNEO DE VOLEYBALL",
    done: true,
    description:
      "Participar en un torneo de voleibol brinda numerosos beneficios para los "
      +"estudiantes. Fomenta el trabajo en equipo, la comunicación y la colaboración, "
      +"mejorando habilidades sociales clave. Además, promueve la disciplina, "
      +"la perseverancia y la gestión del tiempo equilibrando el deporte y "
      +"estudios. La competencia en el torneo ayuda a estimular el espíritu "
      +"competitivo.",
    fecha: "Marzo",
  },
  {
    img: "TorneoVideoJuegos",
    name: "TORNEO DE VIDEOJUEGOS",
    done: true,
    description:
      "SGC es una actividad de recreación competitiva que organiza el Comité de " +
      "Estudiantes de Ingeniería Telemática (CEIT), la cual se viene realizando " +
      "desde hace 6 años, reuniendo a estudiantes de distintas carreras de PUCMM y, " +
      "especialmente, a aquellos que son fanáticos de los videojuegos. Este evento se " +
      "ha convertido en un símbolo del CEIT.",
    fecha: "Mayo",
  },
  {
    img: "DayPass",
    name: "PUCMM DAY PASS 2023",
    done: false,
    description:
      "PUCMM DAY PASS es una oportunidad que brinda la Pontificia Universidad Católica" +
      " Madre y Maestra (PUCMM) para que cualquier persona de cualquier institución se" +
      " pueda convertir en estudiante" +
      " de la universidad por un día completo. Durante la estadía como estudiante de la universidad," +
      " el estudiante podrá asistir a clases como todo un universitario de la PUCMM. ",
    fecha: "Junio",
  },
  {
    img: "MercadoLaboral",
    name: "MERCADO LABORAL ITT",
    done: false,
    description:
      "Mercado Laboral de Ingeniería Telemática, es una actividad realizada anualmente, " +
      "donde escucharemos los testimonios de profesionales con años de experiencia en el" +
      " área. Esta tiene el fin de encaminar a todos los estudiantes de ingeniería " +
      "telemática hacia las posibles y mejores vías para orientarnos y actualizarnos " +
      " cómo va evolucionando el mercado laboral de ITT.",
    fecha: "Julio",
  },

  // {
  //   img: "RaspberryJAM",
  //   name: "Taller Raspberry JAM Dominicana",
  //   done: false
  //   description:
  //     "El Raspberry Jam es una comunidad que organiza eventos independientes, " +
  //     "donde personas de diferentes niveles de experiencia se unen con el fin de " +
  //     "compartir conocimientos, aprender sobre nuevas tecnologías y conocer a otros" +
  //     " entusiastas relacionados con Raspberry PI. Es una actividad anual " +
  //     "realizada para fomentar el uso de las Raspberry Pi en proyectos.",
  //   fecha: "Sin definir",
  // },
  {
    img: "Torneo_Redes",
    name: "TORNEO DE REDES ITT",
    done: false,
    description:
      "Una de las actividades que realizamos es el Torneo de Redes, el cual " +
      "tiene como objetivo incentivar el desarrollo en habilidades concernientes a" +
      " las comunicaciones a distancia y el mundo de las telecomunicaciones," +
      " abarcando tanto la resolución del problema como" +
      " el diseño para la implementación de las posibles soluciones en el área de las"+
      " telecomunicaciones y las redes informáticas.",
    fecha: "Septiembre",
  },
];

export default actividadesData;
