let i = 5;
export const infoCarousel = [
  // {
  //   number: i - 4,
  //   title: "MERCADO LABORAL INTERNACIONAL",
  //   img: "mercadolaboralinternacional",
  // },
  {
    number: i - 3,
    title: "DAYPASS PUCMM 2k23",
    img: "daypass",
  },
  {
    number: i - 2,
    title: "Torneo de VideoJuegos 2k23",
    img: "torneovideojuegos",
  },
  {
    number: i - 1,
    title: "Torneo de Voleyball 2k23",
    img: "torneovoleyball",
  },
  {
    number: i,
    title: "OPENHOUSE PUCMM 2k23",
    img: "openhouse",
  },
];

export default infoCarousel;
