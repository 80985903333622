import { Typography, Tooltip } from "@material-tailwind/react";
import { Footer } from "../widgets/layout/footer";
import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "@material-tailwind/react";
import { directivaData } from "../data/directiva";

export function Directiva() {
  function tipoimagen(imagen) {
    if (imagen === "Nazaret") {
      return (
        <img alt="Nazaret - Presidenta" src={require("./../img/Nazaret.png")} />
      );
    }
    if (imagen === "Guillermo") {
      return (
        <img
          alt="Guillermo - Vicepresidente"
          src={require("./../img/Guillermo.png")}
        />
      );
    }
    if (imagen === "Nicole") {
      return (
        <img alt="Nicole - Secretaria" src={require("./../img/Nicole.png")} />
      );
    }
    if (imagen === "Santiago") {
      return (
        <img alt="Santiago - Tesorero" src={require("./../img/Santiago.png")} />
      );
    }
    if (imagen === "Rocett") {
      return (
        <img alt="Roccet - Mercadeo" src={require("./../img/Rocett.png")} />
      );
    }
    if (imagen === "Marcel") {
      return (
        <img alt="Marcel - Tecnología💻" src={require("./../img/Marcel.png")} />
      );
    }
    if (imagen === "Enmanuel") {
      return (
        <img
          alt="Enmanuel - Logística"
          src={require("./../img/Enmanuel.png")}
        />
      );
    }
    if (imagen === "Andrys") {
      return (
        <img
          alt="Andrys - Logística"
          src={require("./../img/Foto CEIT - Andrys.png")}
        />
      );
    }
  }
  return (
    <>
      <section className="relative block h-[50vh]">
        <div className="bg-profile-background absolute top-0 h-full w-full bg-[url('https://blogthinkbig.com/wp-content/uploads/sites/4/2019/12/technology-cable-electricity-energy-electronic-device-electrical-supply-104676-pxhere.com_-e1577344842465.jpg?resize=450%2C220')] bg-cover bg-center" />
        <div className="absolute top-0 h-full w-full bg-black/75 bg-cover bg-center" />
      </section>
      <section className="relative bg-blue-gray-50/50 pt-16 pb-2 px-4">
        <div className="container mx-auto">
          <div className="relative mb-6 -mt-64 flex w-full min-w-0 flex-col break-words rounded-3xl bg-white shadow-xl shadow-gray-500/5">
            <div className="px-6">
              <div className="flex flex-wrap justify-center">
                <div className="flex w-full justify-center px-4 lg:order-2 lg:w-3/12"></div>
              </div>
              <div className="my-8 text-center">
                <Typography variant="h2" color="blue-gray" className="mb-2">
                  DIRECTIVA CEIT 2023
                </Typography>
                <div
                  style={{
                    backgroundColor: "#005EBC",
                    height: "2px",
                    width: "50%",
                    margin: "auto",
                  }}
                ></div>
              </div>

              <div className="mt-8 mb-10 grid grid-cols-1 gap-6 gap-x-124 md:grid-cols-2 xl:grid-cols-4">
                {directivaData.map(
                  ({
                    key,
                    img,
                    nombre,
                    cargo,
                    descripcion,
                    instagram,
                    linkedin,
                  }) => (
                    <Card className="max-w-[24rem] overflow-hidden">
                      <CardHeader
                        floated={false}
                        shadow={false}
                        color="transparent"
                        className="m-0 rounded-none"
                      >
                        {tipoimagen(img)}
                      </CardHeader>
                      <CardBody>
                        <Typography variant="h4" color="blue-gray">
                          {nombre}
                        </Typography>
                        <Typography
                          variant="lead"
                          color="black"
                          className="leading-6 mt-3 font-normal text-sm text-justify"
                        >
                          {descripcion}
                        </Typography>
                      </CardBody>{" "}
                      <Typography className="font-normal text-center">
                        {cargo}
                      </Typography>
                      <CardFooter className="flex justify-center gap-7 pt-2">
                        {/* <Tooltip content="Instagram"> */}
                        <Tooltip content="Instagram">
                          <Typography
                            as="a"
                            href={instagram}
                            variant="lead"
                            color="purple"
                            target="_blank"
                            textGradient
                          >
                            <i className="fab fa-instagram" />
                          </Typography>
                        </Tooltip>
                        {linkedin && (
                          <Tooltip content="Linkedin">
                            <Typography
                              as="a"
                              href={linkedin}
                              variant="lead"
                              color="blue"
                              target="_blank"
                              textGradient
                            >
                              <i className="fab fa-linkedin" />
                            </Typography>
                          </Tooltip>
                        )}
                        {/* </Tooltip> */}
                      </CardFooter>
                    </Card>
                  )
                )}
              </div>

              <div className="mt-16 text-center">
                <Typography variant="h2" color="blue-gray" className="mb-2">
                  COMITE CEIT 2023
                </Typography>
                <div
                  style={{
                    backgroundColor: "#005EBC",
                    height: "2px",
                    width: "50%",
                    margin: "auto",
                  }}
                ></div>
              </div>

              <div className="w-3/4 mt-8 mb-10 mx-auto">
                <img
                  alt="Ing. Carlos Camacho"
                  src={require("./../img/ComiteCEIT2023.jpeg")}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-blue-gray-50/50">
        <Footer />
      </div>
    </>
  );
}

export default Directiva;
