import { Typography } from "@material-tailwind/react";
import { Footer } from "../widgets/layout/footer";
import React from "react";
import { actividadesData } from "../data/actividades";
import actividadesDeportivas from "../data/info-actividadesDeportivas";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "@material-tailwind/react";

export function Actividades() {
  function tipoimagen(imagen) {
    if (imagen === "OpenHouse_PUCMM") {
      return (
        <img
          alt="OpenHouse PUCMM"
          src={require("./../img/OPENHOUSEPUCMM.png")}
        />
      );
    }
    if (imagen === "TorneoVideoJuegos") {
      return (
        <img
          alt="Torneo de videojuegos"
          src={require("./../img/torneo_videojuegos.png")}
        />
      );
    }
    if (imagen === "Torneo_Redes") {
      return (
        <img alt="Torneo de redes" src={require("./../img/torneo_redes.png")} />
      );
    }
    if (imagen === "RaspberryJAM") {
      return (
        <img alt="Raspberry JAM" src={require("./../img/raspberry_jam.png")} />
      );
    }
    if (imagen === "MercadoLaboral") {
      return (
        <img
          alt="Mercado Laboral"
          src={require("./../img/mercado_laboral.png")}
        />
      );
    }

    if (imagen === "DayPass") {
      return <img alt="Day Pass" src={require("./../img/DayPass.jpg")} />;
    }

    if (imagen === "Voleyball") {
      return (
        <img
          alt="Torneo de Voleyball"
          src={require("./../img/ActividadDeportivaVoleyball.png")}
        />
      );
    }
  }

  function isDone(done, name, ref) {
    if (done) {
      return <p style={{ color: "black" }}>{name}</p>;
    } else {
      return <p style={{ color: "black" }}>{name}</p>;
    }
  }

  return (
    <>
      <section className="relative block h-[50vh]">
        <div className="bg-profile-background absolute top-0 h-full w-full bg-[url('https://blogthinkbig.com/wp-content/uploads/sites/4/2019/12/technology-cable-electricity-energy-electronic-device-electrical-supply-104676-pxhere.com_-e1577344842465.jpg?resize=450%2C220')] bg-cover bg-center" />
        <div className="absolute top-0 h-full w-full bg-black/75 bg-cover bg-center" />
      </section>
      <section className="relative bg-blue-gray-50/50 pt-16 pb-2 px-4">
        <div className="container mx-auto">
          <div className="relative mb-6 -mt-64 flex w-full min-w-0 flex-col break-words rounded-3xl bg-white shadow-xl shadow-gray-500/5">
            <div className="px-6">
              <div className="flex flex-wrap justify-center">
                <div className="flex w-full justify-center px-4 lg:order-2 lg:w-3/12"></div>
              </div>
              <div className="my-8 text-center">
                <Typography variant="h2" color="blue-gray">
                  ACTIVIDADES CEIT
                </Typography>
                <div
                  style={{
                    backgroundColor: "#005EBC",
                    height: "2px",
                    width: "50%",
                    margin: "auto",
                  }}
                ></div>
              </div>

              <div className="mb-10 text-center">
                <div className="mt-2 flex flex-wrap justify-center">
                  <div className="w-full flex-col items-center px-4 lg:w-9/12">
                    <Typography className="text-justify mb-8 font-normal text-blue-gray-1000">
                      La Escuela de Ingeniería en Computación y
                      Telecomunicaciones de la Pontificia Universidad Católica
                      Madre y Maestra (PUCMM) organiza de forma periódica una
                      serie de actividades durante cada año escolar en conjunto
                      con el{" "}
                      <b>
                        Comité de Estudiantes de Ingeniería Telemática (CEIT)
                      </b>
                      , con el propósito principal de incentivar el desarrollo
                      de saberes, así como también el pensamiento crítico, el
                      trabajo en equipo y la continuación en los estudios
                      relacionados con la carrera de Ingeniería Telemática.
                      Algunas de estas son las siguientes:
                    </Typography>
                  </div>
                </div>
                <div className="mt-8 grid grid-cols-1 gap-6 gap-x-124 md:grid-cols-2 xl:grid-cols-4">
                  {actividadesData.map(
                    ({ key, img, name, description, fecha, done }) => (
                      <Card className="max-w-[24rem] overflow-hidden">
                        <CardHeader
                          floated={false}
                          shadow={false}
                          color="transparent"
                          className="m-0 rounded-none"
                        >
                          {tipoimagen(img)}
                        </CardHeader>
                        <CardBody>
                          <Typography variant="h4" color="blue-gray">
                            {isDone(done, name, img)}
                          </Typography>
                          <Typography
                            variant="lead"
                            color="black"
                            className="leading-6 mt-3 font-normal text-sm text-justify"
                          >
                            {description}
                          </Typography>
                        </CardBody>
                        <CardFooter className="flex items-center justify-between">
                          <div className="flex items-center -space-x-3"></div>
                          <Typography className="font-normal">
                            {fecha}
                          </Typography>
                        </CardFooter>
                      </Card>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-blue-gray-50/50">
        <Footer />
      </div>
    </>
  );
}

export default Actividades;
