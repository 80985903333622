import { Typography } from "@material-tailwind/react";
import { Footer } from "../widgets/layout/footer";
import React from "react";
import { GaleriaImagenes } from "./GaleriaImagenes.js";
import "./../styles/Galeria.css";

export function Galeria() {
  let imagenes = [
    { src: require("../img/Marcel.png"), alt: "Image 1" },
    { src: require("../img/Nazaret.png"), alt: "Image 1" },
    { src: require("../img/Guillermo.png"), alt: "Image 1" },
    { src: require("../img/Nicole.png"), alt: "Image 1" },
    { src: require("../img/Santiago.png"), alt: "Image 1" },
    { src: require("../img/videojuegos1.jpg"), alt: "Image 1" },
    // { src: require("../img/videojuegos1.jpg"), alt: "Image 1" },
  ];

  let imagenesOPENWEEK = [];
  // let imagenesTORNEOVIDEOJUEGOS = [];

  for (let i = 1; i <= 10; i++) {
    const imagen = {
      src: require(`../img/openweek/OPENWEEK${i}.jpg`),
      alt: "OpenWeek 2023 - CEIT",
    };
    imagenesOPENWEEK.push(imagen);
  }

  // for (let i = 1; i <= 10; i++) {
  //   const imagenTorneoVideojuegos = {
  //     src: require(`../img/torneovideojuegos/VIDEOJUEGOS${i}.jpg`),
  //     alt: "OpenWeek 2023 - CEIT",
  //   };

  //   imagenesTORNEOVIDEOJUEGOS.push(imagenTorneoVideojuegos);
  // }

  return (
    <>
      <section className="relative block h-[50vh]">
        <div className="bg-profile-background absolute top-0 h-full w-full bg-[url('https://blogthinkbig.com/wp-content/uploads/sites/4/2019/12/technology-cable-electricity-energy-electronic-device-electrical-supply-104676-pxhere.com_-e1577344842465.jpg?resize=450%2C220')] bg-cover bg-center" />
        <div className="absolute top-0 h-full w-full bg-black/75 bg-cover bg-center" />
      </section>
      <section className="relative bg-blue-gray-50/50 pt-16 pb-2 px-4">
        <div className="container mx-auto">
          <div className="relative mb-6 -mt-64 flex w-full min-w-0 flex-col break-words rounded-3xl bg-white shadow-xl shadow-gray-500/5">
            <div className="px-6">
              <div className="flex flex-wrap justify-center">
                <div className="flex w-full justify-center px-4 lg:order-2 lg:w-3/12"></div>
              </div>
              <div className="my-8 text-center">
                <Typography variant="h2" color="blue-gray">
                  GALERÍA DE IMAGENES
                </Typography>
                <div
                  style={{
                    backgroundColor: "#005EBC",
                    height: "2px",
                    width: "50%",
                    margin: "auto",
                  }}
                ></div>
              </div>

              <Typography variant="h3" color="blue-gray">
                OPEN HOUSE
              </Typography>
              <GaleriaImagenes images={imagenesOPENWEEK}></GaleriaImagenes>
              <br />
              <br />
              {/* <Typography variant="h3" color="blue-gray">
                TORNEO DE VIDEOJUEGOS
              </Typography>
              <GaleriaImagenes
                images={imagenesOPENWEEK}
              ></GaleriaImagenes>
              <br />
              <br /> */}
            </div>
          </div>
        </div>
      </section>
      <div className="bg-blue-gray-50/50">
        <Footer />
      </div>
    </>
  );
}

export default Galeria;
