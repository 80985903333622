export const infoData = [
  {
    number: 1,
    title: "CEIT",
    content:
      "El comité tiene como objetivos: el estudio y desarrollo de los intereses estudiantiles. La búsqueda de formas de desarrollar la capacidad de liderazgo en sus miembros activos. Así como mejorar el estatus del estudiante dentro del esquema de funcionamiento universitario.",
  },
  {
    number: 2,
    title: "Misión ",
    content:
      "El CEIT tiene como misión: el intermediar la relación entre los estudiantes de la carrera, el aparato académico y administrativo de la universidad. Proporcionar a los estudiantes orientaciones generales y favorecer al desarrollo de su formación integral.",
  },
  {
    number: 3,
    title: "Visión",
    content:
      "Incentivar la búsqueda de nuevos horizontes y posibilidades dentro de la carrera. Al igual la formación de programas que beneficien en el ámbito académicos a los estudiantes. Luchar por la dignificación y profesionalización de los estudiantes de nuestra carrera y otras a fines.",
  },
  {
    number: 4,
    title: "Objetivos",
    content:
      "El comité tiene como objetivos; el estudio y desarrollo de los intereses estudiantiles. La búsqueda de forma de desarrollar en sus miembros activos la capacidad de liderazgo. Así como mejorar el estatus del estudiante dentro de esquema de funcionamiento universitario.",
  },
];

export default infoData;
