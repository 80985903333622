export const infodocentes = [
  {
    id: 1,
    img: "ALiz",
    nombre: "Alejandro Liz",
    correo: "aj.liz@ce.pucmm.edu.do",
  },
  {
    id: 2,
    img: "JLAlonso",
    nombre: "José Luis Alonso",
    correo: "jl.alonso@ce.pucmm.edu.do",
  },
  {
    id: 3,
    img: "LBeato",
    nombre: "Lisibonny Beato",
    correo: "le.beato@ce.pucmm.edu.do",
  },
  {
    id: 4,
    img: "MMoronta",
    nombre: "Miguel Moronta",
    correo: "mt.moronta@ce.pucmm.edu.do",
  },
];

export default infodocentes;
