export const directivaData = [
  {
    img: "Nazaret",
    nombre: "Nazaret Cabrera",
    cargo: "Presidenta🛰",
    descripcion:
      "Nazaret se distingue por su entrega al comité con objetivos claros de que es lo que quiere lograr al finalizar su gestion.",
    instagram: "https://www.instagram.com/nazaret_cabrera07/",
    linkedin:
      "https://www.linkedin.com/in/nazaret-cabrera-pe%C3%B1a-b45133224/",
  },
  {
    img: "Guillermo",
    nombre: "Guillermo Pérez",
    cargo: "Vicepresidente📡",
    descripcion:
      "Guillermo es un joven creativo, destacado por su disposición y tenacidad. Estará ejerciendo como vicepresidente.",
    instagram: "https://www.instagram.com/guillermodpm/",
    linkedin: "https://www.linkedin.com/in/guillermo-p%C3%A9rez-42017b200/",
  },
  {
    img: "Nicole",
    nombre: "Nicole Díaz",
    cargo: "Secretaria📋",
    descripcion:
      "Nicole se distingue por su organización y colaboración para el buen desempeño de las actividades del comité.",
    instagram: "https://www.instagram.com/n_d.t/",
    // linkedin: "",
  },
  {
    img: "Santiago",
    nombre: "Santiago Genao",
    cargo: "Tesorero💸",
    descripcion:
      "Santiago es un joven responsable, quien se encargará del manejo de los fondos del CEIT.",
    instagram: "https://www.instagram.com/genaoking/",
    linkedin: "https://www.linkedin.com/in/santiago-genao-89b1691b7/",
  },
  {
    img: "Rocett",
    nombre: "Rocett Núñez",
    cargo: "Encargada de Mercadeo y Promoción📷",
    descripcion:
      "Rocett se encargará de brindar apoyo en el manejo de las redes sociales del CEIT, así como la promoción del mismo.",
    instagram: "https://www.instagram.com/rocettn/",
    linkedin: "https://www.linkedin.com/in/rocett-n%C3%BA%C3%B1ez-rosario-07632027a",
  },
  {
    img: "Marcel",
    nombre: "Marcel Pérez",
    cargo: "Encargado de Multimedia y Tecnología💻",
    descripcion:
      "Marcel se encargará de proporcionar soluciones técnicas efectivas ante cualquier dificultad.",
    instagram: "https://www.instagram.com/marcel0059/",
    linkedin:
      "https://www.linkedin.com/in/marcel-p%C3%A9rez-fern%C3%A1ndez-a810a9110/",
  },
  {
    img: "Andrys",
    nombre: "Andrys Saldaña",
    cargo: "Encargado de Logística y Eventos🔗",
    descripcion:
      "Andrys es un joven carismático, el cual se encargará de la organización y logística de los eventos del CEIT.",
    instagram: "https://www.instagram.com/andrys_05/",
    // linkedin: "",
  },
];

export default directivaData;
