export const patrocinadores = [
  {
    img: "RadicalOne",
    nombre: "Radical One",
    descripcion:
      "Empresa de consultoría tecnológica que se especializa en" +
      " implementar y extender software de computación en la nube.",
    instagram: "https://www.instagram.com/theradical1s/",
  },
  {
    img: "Iteprof",
    nombre: "Iteprof",
    descripcion:
      "Caracterizados por ofrecer consultoría y servicios de primera " +
      "clase en la industria de la Tecnología de la Información y la Comunicación.",
    instagram: "https://www.instagram.com/iteprof/",
  },
];

export default patrocinadores;
