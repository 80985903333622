export const infoEscuela = [
  {
    number: 1,
    name: "Ing. Carlos Camacho",
    img: "CarlosCamacho",
    position: "Director de Ing. Telemática",
    description:
      "Egresado de Ingeniería Telemática, magíster en Tecnología Educativa, profesor por vocación.",
    contact: "ccamacho@pucmm.edu.do",
    instagram: "https://www.instagram.com/ccamachog1/",
    linkedin: "https://www.linkedin.com/in/ccamachog/"
  },
  // {
  //   number: 2,
  //   name: "Genesis",
  //   img: "GenesisSecretaria",
  //   position: "Secretaria de Ing. Telemática",
  //   description:
  //     "Secretaria del departamento",
  //   contact: "ccamacho@pucmm.edu.do",
  // },
];

export default infoEscuela;
