import PropTypes from "prop-types";
import { Typography, IconButton } from "@material-tailwind/react";

const year = new Date().getFullYear();

export function Footer({ title, description, socials, menus, copyright }) {
  return (
    <footer className="relative px-4 pt-8 pb-6 bg-sky-400">
      <div className="container mx-auto">
        <div className="flex flex-wrap pt-6 text-center lg:text-left">
          <div className="w-full px-4 lg:w-6/12">
            <Typography
              style={{ display: "inline" }}
              variant="h2"
              className="mb-4"
              color="blue-gray"
            >
              {title}
            </Typography>

            <Typography className="font-normal text-blue-gray-500">
              {description}
            </Typography>
            <div className="mx-auto mt-6 mb-8 flex justify-center gap-2 md:mb-0 ">
              {socials.map(({ color, name, path }) => (
                <a
                  key={name}
                  href={path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton color="white" className="rounded-full">
                    <Typography color={color}>
                      <i className={`${name}`} />
                    </Typography>
                  </IconButton>
                </a>
              ))}
            </div>
          </div>
          <div className="mx-auto mt-12 grid w-max grid-cols-2 gap-24 lg:mt-0">
            {menus.map(({ name, items }) => (
              <div key={name}>
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="mb-2 block font-medium uppercase"
                >
                  {name}
                </Typography>
                <ul className="mt-3">
                  {items.map((item) => (
                    <li key={item.name}>
                      <Typography
                        as="a"
                        href={item.path}
                        target="_blank"
                        rel="noreferrer"
                        variant="small"
                        className="mb-2 block font-normal text-blue-gray-500 hover:text-blue-gray-700"
                      >
                        {item.name}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <hr className="my-6 border-gray-300" />
        <div className="flex flex-wrap items-center justify-center md:justify-between">
          <a
            href="https://www.pucmm.edu.do/"
            target="_blank"
            style={{ margin: "auto", paddingBottom: "30px" }}
          >
            <img
              style={{ margin: "auto", height: "70px" }}
              alt="PUCMM"
              src={require("./../../img/PUCMM_2.png")}
            />
          </a>
          <div className="mx-auto w-full px-4 text-center">
            <Typography
              variant="small"
              className="font-normal text-blue-gray-500"
            >
              {copyright}
            </Typography>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.defaultProps = {
  title: "CEIT",
  description: "Comite de Estudiantes de Ingeniería Telemática",
  socials: [
    {
      color: "blue",
      name: "fa-brands fa-facebook",
      path: "https://www.facebook.com/ceitpucmm",
    },
    {
      color: "light-blue",
      name: "fa-brands fa-twitter",
      path: "https://twitter.com/CEIT_PUCMM",
    },
    {
      color: "purple",
      name: "fa-brands fa-instagram",
      path: "https://www.instagram.com/ceit_pucmm/",
    },
    {
      color: "blue",
      name: "fa-brands fa-linkedin",
      path: "https://www.linkedin.com/in/ceit-pucmm-b5a432266/",
    },
    {
      color: "blue",
      name: "fas fa-link",
      path: "https://linktr.ee/ceit_pucmm",
    },
    {
      color: "red",
      name: "fa-brands fa-youtube",
      path: "https://www.youtube.com/@ceitpucmmsti8993",
    },
    {
      color: "blue",
      name: "fa-brands fa-discord",
      path: "https://discord.gg/3wvtw4nYEE",
    },
  ],
  menus: [
    {
      name: "Recursos mas usados",
      items: [
        {
          name: "Informacion sobre carrera",
          path: "https://eict.pucmm.edu.do/telematica/",
        },
        {
          name: "Pensum nuevo de la carrera",
          path: "https://www.pucmm.edu.do/ingenierias/Documents/pensum-telematica.pdf",
        },
        {
          name: "Informacion sobre la escuela",
          path: "https://eict.pucmm.edu.do/",
        },
      ],
    },
    {
      name: "Otros Recursos",
      items: [
        {
          name: "Proyectos de grado",
          path: "https://eict.pucmm.edu.do/proyectos-de-grado/",
        },
        {
          name: "Pasantías",
          path: "https://eict.pucmm.edu.do/pasantias/",
        },
        {
          name: "Contacto con escuela",
          path: "https://eict.pucmm.edu.do/contacto/",
        },
      ],
    },
  ],
  copyright: (
    <>
      Copyright © {year} {"CEIT"}
      {/* <br></br>Material Tailwind */}
    </>
  ),
};

Footer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  socials: PropTypes.arrayOf(PropTypes.object),
  menus: PropTypes.arrayOf(PropTypes.object),
  copyright: PropTypes.node,
};

Footer.displayName = "/src/widgets/layout/footer.jsx";

export default Footer;
