export const preguntasRecientes = [
  {
    numero: 1,
    pregunta:
      "¿En qué áreas puede ejercer la carrera un telemático egresado de la PUCMM?",
    respuesta:
      "Generalmente, el egresado de telemática se dedica al desarrollo de software (ya sea programación web, de escritorio, o de aplicaciones) o se especializa en alguno de los varios campos que ofrecen las redes (seguridad, cloud, telefonía, diseño de redes, y demás).",
  },
  {
    numero: 2,
    pregunta: "¿Cuál es la dificultad que tiene la carrera de Ingeniería Telemática?",
    respuesta:
      "Cualquier carrera de ingeniería tiende a ser difícil, debido a que, por definición, el ingeniero debe poder resolver cualquier problema (dentro de su área del conocimiento), y en el caso de que este no sepa cómo resolver el problema debe investigar y aprender a resolverlo. Por lo tanto, la carrera prepara a los estudiantes para que estos puedan enfrentarse a cualquier adversidad en su vida profesional.",
  },
  {
    numero: 3,
    pregunta:
      "¿Qué fortalezas se deben tener en cuenta para optar por la carrera de telemática?",
    respuesta:
      "Primordialmente, todo estudiante que aspire a ser ingeniero telemático debe de tener interés en la tecnología y su evolución. También, es importante tener una mente activa y abierta a cualquier desafío, ya sea en el ámbito matemático y físico como en el área ingenieril (en nuestro caso, programación, redes y sus derivados, electrónica de comunicaciones, y demás).",
  },
  {
    numero: 4,
    pregunta: "¿En qué se diferencia de carreras como electrónica y eléctrica?",
    respuesta:
      "La ingeniería telemática es una rama de la electrónica, sin embargo, el perfil del telemático es dedicado casi únicamente a la telecomunicación, mientras que el electrónico se dedica a proveer soluciones de hardware en las distintas disciplinas de la electrónica. En cuanto a los ingenieros eléctricos, no hay mucha relación con estos, puesto a que los eléctricos se dedican a resolver problemas energéticos, y demás áreas relacionadas.",
  },
  {
    numero: 5,
    pregunta: "¿Cuál es la diferencia entre un Ingeniero en Sistemas y un Ingeniero Telemático?",
    respuesta:
      "Debido a la parte informática del ingeniero telemático tiende a haber confusión entre estas dos ingenierías. El ingeniero en sistemas entra más a fondo a lo que es el desarrollo de software en todas sus interpretaciones y aprende más lenguajes de programación que un telemático. No obstante, los estudiantes de telemática aprenden todo lo necesario para lanzarse al mundo del desarrollo de software, pero este no es el enfoque de la carrera.",
  },
  {
    numero: 6,
    pregunta:
      "¿Qué tipo de trabajos podría realizar un egresado de telemática?",
    respuesta:
      "Los egresados de ingeniería telemática tienen una multitud de opciones en cuando al ámbito laboral, puesto a que esta carrera se dedica a la transferencia, seguridad, y manejo de información. Por lo tanto, es posible trabajar en: hospitales, cadenas de bancas de apuestas, oficinas del gobierno como Edenorte o Coraasan, proveedores de servicio como Claro, Orange, Tricom, y demás. Básicamente, cualquier lugar que transfiera información en volumen puede ser un lugar de trabajo para un telemático.",
  },
  {
    numero: 7,
    pregunta:
      "¿Telemática resulta rentable en cuanto a opción del desempeño del trabajo?",
    respuesta:
      "Realmente, todo depende del empeño que pongas como individuo en el ámbito profesional. La telemática abarca muchas disciplinas, así que debe ser posible encontrar muchas oportunidades rentables, todo depende de que tanto empeño le dediques.",
  },
  {
    numero: 8,
    pregunta:
      "¿Se desarrollan actividades o eventos que permitan demostrar mis habilidades como telemático?",
    respuesta:
      "Si, el Comité de Estudiantes de Ingeniería Telemática y el Departamento de Ingeniería Telemática realizan competencias y torneos en los que los estudiantes de ingeniería telemática pueden competir entre sí para probar sus conocimientos.",
  },
  {
    numero: 9,
    pregunta:
      "¿El departamento de telemática fomenta el aprendizaje mediante charlas o cursos externos al pensum?",
    respuesta:
      "Si, el Comité de Estudiantes de Ingeniería Telemática y su Departamento Académico realizan varias actividades todos los años para promover la carrera y el conocimiento de la misma, además de ayudar al desarrollo personal y profesional de los estudiantes.",
  },
];

export default preguntasRecientes;
