import { React, Fragment, useState } from "react";
import {
  Card,
  CardBody,
  Typography,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import Carousel from "react-bootstrap/Carousel";

import { PageTitle } from "../widgets/layout/page-title";
import { Footer } from "../widgets/layout/footer";
import { infoData } from "../data/info-data";
import infoCarousel from "../data/info-carousel";
import { preguntasRecientes } from "../data/info-preguntasfrecuentes";
import { noticias } from "../data/info-noticias";

export function Home() {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.5 },
  };

  function carouselImg(imagen) {
    if (imagen === "torneovideojuegos") {
      return (
        <img
          alt="Torneo de Videojuegos 2k23"
          src={require("./../img/TorneoVideojuegos2k23.jpeg")}
        />
      );
    }
    if (imagen === "torneovoleyball") {
      return (
        <img
          alt="Torneo de Voleyball 2k23"
          src={require("./../img/Torneo_Voleyball.jpeg")}
        />
      );
    }
    if (imagen === "openhouse") {
      return (
        <img alt="OpenHouse" src={require("./../img/OPENHOUSE2023.jpg")} />
      );
    }
    if (imagen === "daypass") {
      return <img alt="DayPass" src={require("./../img/DAYPASS6.jpg")} />;
    }if (imagen === "mercadolaboralinternacional") {
      return <img alt="DayPass" src={require("./../img/MercadoLaboralExtranjero.jpeg")} />;
    }
  }

  function imagenNoticia(imagen) {
    if (imagen === "discord") {
      return <img alt="Discord" src={require("./../img/Discord.png")} />;
    }
    if (imagen === "linkedin") {
      return <img alt="Linkedin" src={require("./../img/Linkedin.png")} />;
    }
    if (imagen === "instagram") {
      return <img alt="Instagram" src={require("./../img/Instagram.png")} />;
    }
    if (imagen === "facebook") {
      return <img alt="Facebook" src={require("./../img/Facebook.png")} />;
    }
    if (imagen === "youtube") {
      return <img alt="YouTube" src={require("./../img/YouTube.png")} />;
    }
  }

  return (
    <>
      <div className="relative flex h-screen content-center items-center justify-center pt-16 pb-32">
        <div className="z-1 absolute top-0 h-full w-full bg-[url('https://blogthinkbig.com/wp-content/uploads/sites/4/2019/12/technology-cable-electricity-energy-electronic-device-electrical-supply-104676-pxhere.com_-e1577344842465.jpg?resize=450%2C220')] bg-cover bg-center" />
        <div className="absolute top-0 h-full w-full bg-black/75 bg-cover bg-center" />
        <div className="max-w-8xl container relative mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="ml-auto mr-auto w-full px-4 text-center lg:w-8/12">
              <div className="text-4xl">
                <Typography
                  variant="h1"
                  color="white"
                  className="mb-6 mt-40 font-black"
                >
                  COMITE DE ESTUDIANTES DE INGENIERÍA TELEMÁTICA
                </Typography>
              </div>
              <Typography
                variant="lead"
                color="white"
                className="mt-20 opacity-80"
              >
                Bienvenidos a la página oficial del comité de estudiantes de
                Ingeniería Telemática.
                <br />
                <br />
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <section className="px-4 pt-10 pb-28">
        <div className="container">
          <PageTitle heading="Actividades más reciente"></PageTitle>
          <div
            style={{
              backgroundColor: "#005EBC",
              height: "2px",
              width: "50%",
              margin: "auto",
              marginBottom: "10px",
            }}
          ></div>
          <Carousel className="col-md-8 mx-auto">
            {infoCarousel.map(({ number, title, img }) => (
              <Carousel.Item interval={10000}>
                {carouselImg(img)}
                <Carousel.Caption>
                  <p
                    style={{
                      fontWeight: "12px",
                      color: "#fff",
                    }}
                  >
                    <b>{title}</b>
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
          {/* <div className="mt-14">
            <PageTitle heading="Nuestras Redes"></PageTitle>
            <div
              style={{
                backgroundColor: "#005EBC",
                height: "2px",
                width: "50%",
                margin: "auto",
              }}
            ></div>
          </div>
          <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {noticias.map(({ nombre, img, url }) => (
              <div class="p-4 border border-black rounded-md">
                <h2 class="text-lg font-bold text-center">{nombre}</h2>
                <a target="_blank" href={url}>
                  <div
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover"
                    }}
                  >
                    {imagenNoticia(img)}
                  </div>
                </a>
              </div>
            ))}
          </div> */}

          <div className="mt-14">
            <PageTitle heading="Sobre nosotros"></PageTitle>
            <div
              style={{
                backgroundColor: "#005EBC",
                height: "2px",
                width: "50%",
                margin: "auto",
              }}
            ></div>
          </div>
          <div className="mt-2 grid grid-cols-1 gap-12 gap-x-124 md:grid-cols-2 xl:grid-cols-4">
            {infoData.map(({ number, title, content }) => (
              <Card className="mt-6">
                <CardBody>
                  <Typography variant="h5" color="blue-gray" className="mb-2">
                    {title}
                  </Typography>
                  <Typography className="text-justify">{content}</Typography>
                </CardBody>
              </Card>
            ))}
          </div>

          {/* PREGUNTAS FRECUENTES */}
          <div className="mt-10">
            <PageTitle heading="Preguntas Frecuentes"></PageTitle>
            <div
              style={{
                backgroundColor: "#005EBC",
                height: "2px",
                width: "100%",
                margin: "auto",
              }}
            ></div>
            <div
              style={{
                border: "1px solid #000",
                width: "100%",
                margin: "auto",
              }}
            >
              <Fragment>
                {preguntasRecientes.map(({ numero, pregunta, respuesta }) => (
                  <Accordion
                    style={{ padding: "10px" }}
                    open={open === numero}
                    animate={customAnimation}
                  >
                    <AccordionHeader
                      style={{ margin: "auto" }}
                      onClick={() => handleOpen(numero)}
                    >
                      &emsp;&nbsp;&nbsp;{pregunta}
                    </AccordionHeader>
                    <AccordionBody>
                      <p style={{ textAlign: "justify" }}>{respuesta}</p>
                    </AccordionBody>
                  </Accordion>
                ))}
              </Fragment>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-blue-gray-50/50">
        <Footer />
      </div>
    </>
  );
}

export default Home;
