import { Typography, Button } from "@material-tailwind/react";
import { MapPinIcon } from "@heroicons/react/24/solid";
import { Footer } from "../widgets/layout/footer";
import { featuresData } from "../data/features-data";
import { FeatureCard } from "../widgets/cards/feature-card";
import { infodocentes } from "../data/info-docentes";

import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Tooltip,
} from "@material-tailwind/react";
import infoEscuela from "../data/info-escuela";

export function Informacion() {
  function imagenDocentes(imagen) {
    if (imagen === "ALiz") {
      return <img alt="ALiz" src={require("./../img/ALiz.jpg")} />;
    }
    if (imagen === "JLAlonso") {
      return <img alt="JLAlonso" src={require("./../img/JLAlonso.jpg")} />;
    }
    if (imagen === "LBeato") {
      return <img alt="LBeato" src={require("./../img/LBeato.jpg")} />;
    }
    if (imagen === "MMoronta") {
      return <img alt="MMoronta" src={require("./../img/MMoronta.jpg")} />;
    }
  }

  function imagenEscuela(imagen) {
    if (imagen === "CarlosCamacho") {
      return (
        <img
          className="mx-auto"
          alt="Ing. Carlos Camacho"
          src={require("./../img/Camacho.jpg")}
        />
      );
    }
  }

  return (
    <>
      <section className="relative block h-[50vh]">
        <div className="bg-profile-background absolute top-0 h-full w-full bg-[url('https://blogthinkbig.com/wp-content/uploads/sites/4/2019/12/technology-cable-electricity-energy-electronic-device-electrical-supply-104676-pxhere.com_-e1577344842465.jpg?resize=450%2C220')] bg-cover bg-center" />
        <div className="absolute top-0 h-full w-full bg-black/75 bg-cover bg-center" />
      </section>
      <section className="relative bg-blue-gray-50/50 pt-16 pb-2 px-4">
        <div className="container mx-auto">
          <div className="relative mb-6 -mt-64 flex w-full min-w-0 flex-col break-words rounded-3xl bg-white shadow-xl shadow-gray-500/5">
            <div className="px-6">
              <div className="flex flex-wrap justify-center">
                <div className="flex w-full justify-center px-4 lg:order-2 lg:w-3/12"></div>
              </div>
              <div className="my-8 text-center">
                <Typography variant="h2" color="blue-gray" className="mb-2">
                  INGENIERÍA TELEMÁTICA
                </Typography>
                <div className="mb-10 flex items-center justify-center gap-2">
                  <MapPinIcon className="-mt-px h-4 w-4 text-blue-gray-700" />
                  <Typography className="font-medium text-blue-gray-700">
                    Pontificia Universidad Católica Madre y Maestra
                  </Typography>
                </div>
              </div>
              <div className="mb-10 border-t py-6 text-center">
                <div className="text-3xl font-bold leading-normal mt-0 mb-2 text-black-800">
                  Ingeniería Telemática:
                </div>
                <div
                  style={{
                    backgroundColor: "#005EBC",
                    height: "2px",
                    width: "50%",
                    margin: "auto",
                  }}
                ></div>
                <div className="mt-2 flex flex-wrap justify-center">
                  <div className="w-full flex-col items-center px-4 lg:w-9/12">
                    <Typography className="text-justify mb-8 font-normal text-blue-gray-700">
                      La <b>Real Academia de la Lengua Española</b> define la
                      <b> telemática</b> como: “La aplicación de las técnicas de
                      la
                      <b> telecomunicación</b> y de la información a la
                      transmisión de
                      <b> información computarizada</b>”. Es decir, un{" "}
                      <b>telemático </b>se encarga de <b>analizar</b> e{" "}
                      <b>implementar</b> servicios y aplicaciones que{" "}
                      <b>faciliten la transmisión</b> de información, utilizando
                      el medio más eficiente y seguro.
                      <br />
                      {/* <br /> El enfoque primario de el pensum de Ingeniería
                      Telemática es el desarrollo de habilidades en la solución
                      de problemas de telecomunicación. No obstante, debido a
                      que la carrera es la unión de la informática y una rama de
                      la electrónica, el egresado también obtendrá conocimientos
                      en las áreas de programación, base de datos, electrónica
                      análoga y digital, electrónica de comunicaciones, y demás
                      campos relacionados. */}
                    </Typography>
                  </div>
                </div>{" "}
                <div className="text-3xl font-bold leading-normal mt-0 mb-2 text-black-800">
                  Estudio en PUCMM:
                </div>
                <div className="w-3/4 m-auto mb-10 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
                  {featuresData.map(({ color, title, icon, description }) => (
                    <FeatureCard
                      key={title}
                      color={color}
                      title={title}
                      icon={React.createElement(icon, {
                        className: "w-5 h-5",
                      })}
                      description={description}
                    />
                  ))}
                </div>
                {/* <YoutubeEmbed embedId="eZzQB_s5EV4" /> */}
                <br />
                <div className="text-3xl font-bold leading-normal mt-0 mb-2 text-black-800">
                  Perfil del egresado
                </div>
                <div className="mt-2 flex flex-wrap justify-center">
                  <div className="w-full flex-col items-center px-4 lg:w-9/12">
                    <Typography className="text-justify mb-8 font-normal text-blue-gray-700">
                      El <b>egresado</b> de la carrera de{" "}
                      <b>Ingeniería Telemática</b> es un profesional líder con
                      las competencias, conocimientos y habilidades necesarias
                      para <b>aportar a la sociedad</b> y a su entorno
                      soluciones científicas apegadas a los principios éticos,
                      humanísticos y cristianos.
                      <br />
                      <br />
                      El Ingeniero Telemático puede ejercer la profesión como
                      empresario creador de empleos y riquezas, como profesional
                      independiente o como colaborador responsable y eficiente
                      para lograr que dispositivos electrónicos interactúen con
                      otros situados geográficamente distantes, usando redes de
                      telecomunicaciones y reconociendo las características
                      esenciales de la información.
                    </Typography>
                  </div>
                </div>
                <Typography
                  as="a"
                  href={"https://eict.pucmm.edu.do/telematica/"}
                  target="_blank"
                  rel="noreferrer"
                  variant="small"
                  className="mb-10 mt-2 block font-normal text-blue-gray-500 hover:text-blue-gray-700"
                >
                  <Button variant="text">Conocer mas sobre Telemática </Button>
                </Typography>
                <div className="text-3xl font-bold leading-normal mt-0 mb-2 text-black-800">
                  Sobre la escuela
                </div>
                <div
                  style={{
                    backgroundColor: "#005EBC",
                    height: "2px",
                    width: "50%",
                    margin: "auto",
                  }}
                ></div>
                <div className="mt-8 mb-10 flex justify-center items-center">
                  {infoEscuela.map(
                    ({
                      number,
                      name,
                      img,
                      position,
                      description,
                      contact,
                      instagram,
                      linkedin,
                    }) => (
                      <Card>
                        <CardHeader
                          floated={false}
                          color="transparent"
                          className="m-0 rounded-none"
                        >
                          {imagenEscuela(img)}
                        </CardHeader>
                        <CardBody>
                          <Typography
                            variant="h4"
                            color="blue-gray"
                            className="mb-2"
                          >
                            {name}
                          </Typography>
                          <Typography
                            color="blue"
                            className="font-medium"
                            textGradient
                          >
                            {position}
                          </Typography>
                          <Typography className="font-medium">
                            {description}
                          </Typography>
                        </CardBody>
                        <CardFooter className="flex justify-center gap-7 pt-2">
                          <Tooltip content={contact}>
                            <Typography
                              as="a"
                              variant="lead"
                              color="blue"
                              textGradient
                            >
                              <i className="fa-solid fa-envelope" />
                            </Typography>
                          </Tooltip>
                          <Tooltip content="Instagram">
                            <Typography
                              as="a"
                              target="_blank"
                              href={instagram}
                              variant="lead"
                              color="purple"
                              textGradient
                            >
                              <i className="fab fa-instagram" />
                            </Typography>
                          </Tooltip>
                          <Tooltip content="Linkedin">
                            <Typography
                              as="a"
                              target="_blank"
                              href={linkedin}
                              variant="lead"
                              color="blue"
                              textGradient
                            >
                              <i className="fab fa-linkedin" />
                            </Typography>
                          </Tooltip>
                        </CardFooter>
                      </Card>
                    )
                  )}
                </div>
                <br />
                <div className="text-3xl font-bold leading-normal mt-0 mb-2 text-black-800">
                  DOCENTES
                </div>
                <div className="mt-8 mb-10 grid grid-cols-1 gap-6 gap-x-124 md:grid-cols-2 xl:grid-cols-4">
                  {infodocentes.map(({ id, img, nombre, correo }) => (
                    <Card className="max-w-[24rem] overflow-hidden">
                      <CardHeader
                        floated={false}
                        shadow={false}
                        color="transparent"
                        className="m-0 rounded-none"
                      >
                        {imagenDocentes(img)}
                      </CardHeader>
                      <CardBody>
                        <Typography variant="h4" color="blue-gray">
                          {nombre}
                        </Typography>
                      </CardBody>{" "}
                      <Typography className="font-normal text-center">
                        <b>Docente</b>
                      </Typography>
                      <CardFooter className="flex justify-center gap-7 pt-2">
                        {/* <Tooltip content="Instagram"> */}
                        <Typography
                          color="blue"
                          className="font-medium"
                          as="a"
                          href={`mailto:${correo}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {correo}
                        </Typography>
                        {/* </Tooltip> */}
                      </CardFooter>
                    </Card>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-blue-gray-50/50">
        <Footer />
      </div>
    </>
  );
}

export default Informacion;
