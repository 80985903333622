import { Footer } from "../widgets/layout/footer";
import React from "react";
import { Alert } from "@material-tailwind/react";
import { patrocinadores } from "./../data/patrocinadores";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "@material-tailwind/react";
import { Typography } from "@material-tailwind/react";

export function ClubRedes() {
  function tipoimagen(imagen) {
    if (imagen === "RadicalOne") {
      return <img alt="Radical One" src={require("./../img/RadicalOne.png")} />;
    }
  }
  return (
    <>
      <section className="relative block h-[50vh]">
        <div className="bg-profile-background absolute top-0 h-full w-full bg-[url('https://blogthinkbig.com/wp-content/uploads/sites/4/2019/12/technology-cable-electricity-energy-electronic-device-electrical-supply-104676-pxhere.com_-e1577344842465.jpg?resize=450%2C220')] bg-cover bg-center" />
        <div className="absolute top-0 h-full w-full bg-black/75 bg-cover bg-center" />
      </section>
      <section className="relative bg-blue-gray-50/50 pt-16 pb-2 px-4">
        <div className="container mx-auto">
          <div className="relative mb-6 -mt-64 flex w-full min-w-0 flex-col break-words rounded-3xl bg-white shadow-xl shadow-gray-500/5">
            <div className="px-6">
              <div className="flex flex-wrap justify-center">
                <div className="flex w-full justify-center px-4 lg:order-2 lg:w-3/12"></div>
              </div>
              <div className="my-8 text-center">
                <Alert color="red">CLUB DE REDES - COMING SOON!!</Alert>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-blue-gray-50/50">
        <Footer />
      </div>
    </>
  );
}

export default ClubRedes;
