import { Routes, Route, Navigate } from "react-router-dom";
import { Navbar } from "./widgets/layout/navbar";
import routes from "./routes";

function App() {
  return (
    <>
      {/* MENU */}
      <div className="container absolute left-2/4 z-10 mx-auto -translate-x-2/4 p-4">
        <Navbar routes={routes} />
      </div>

      {/* PARA CUANDO HACEMOS CLICK EN LOS MENU ME LLEVE A LA SIGUIENTE PAGINA */}
      <Routes>
        {routes.map(
          ({ path, element }, key) =>
            element && <Route key={key} exact path={path} element={element} />
        )}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}

export default App;
