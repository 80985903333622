// IMPORTACIONES DE COMPONENTES:
import { Home } from "./pages/home";
import { Informacion } from "./pages/Info";
import { Actividades } from "./pages/Actividades";
import { Directiva } from "./pages/Directiva";
import { Promocion } from "./pages/Promocion";
import { Galeria } from "./pages/Galeria";
import ClubRedes from "./pages/ClubRedes";

// IMPORTACION DE ICONOS
import {
  HomeIcon,
  InformationCircleIcon,
  ClipboardDocumentListIcon,
  UserCircleIcon,
  ChatBubbleBottomCenterIcon,
  ServerIcon,
  PhotoIcon
} from "@heroicons/react/24/solid";

// Distintas rutas:
export const routes = [
  {
    icon: HomeIcon,
    name: "Inicio",
    path: "/",
    element: <Home />,
  },
  {
    icon: InformationCircleIcon,
    name: "Información ITT",
    path: "/informacion",
    element: <Informacion />,
  },
  {
    icon: ClipboardDocumentListIcon,
    name: "Actividades",
    path: "/Actividades",
    element: <Actividades />,
  },
  {
    icon: UserCircleIcon,
    name: "Directiva",
    path: "/Directiva",
    element: <Directiva />,
  },
  {
    icon: ChatBubbleBottomCenterIcon,
    name: "Promoción",
    path: "/Promocion",
    element: <Promocion />,
  },
  {
    icon: PhotoIcon,
    name: "Galería",
    path: "/Galeria",
    element: <Galeria />,
  },
  // {
  //   icon: ServerIcon,
  //   name: "Club de Redes",
  //   path: "/ClubRedes",
  //   element: <ClubRedes />,
  // },
];

export default routes;
